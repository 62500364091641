import React from 'react'
import { FormProvider } from './src/context/form.context'
import { LangProvider } from './src/context/lang.context'

import './src/style/style.css'

export const wrapRootElement = ({ element }) => (
  <LangProvider>
    <FormProvider>{element}</FormProvider>
  </LangProvider>
)
