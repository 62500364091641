exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asia-js": () => import("./../../../src/pages/asia.js" /* webpackChunkName: "component---src-pages-asia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-middle-east-js": () => import("./../../../src/pages/middle-east.js" /* webpackChunkName: "component---src-pages-middle-east-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-🇦🇺-🇳🇿-js": () => import("./../../../src/pages/🇦🇺🇳🇿.js" /* webpackChunkName: "component---src-pages-🇦🇺-🇳🇿-js" */),
  "component---src-pages-🇪🇺-🇺🇦-js": () => import("./../../../src/pages/🇪🇺🇺🇦.js" /* webpackChunkName: "component---src-pages-🇪🇺-🇺🇦-js" */),
  "component---src-pages-🇺🇸-🇨🇦-🇲🇽-js": () => import("./../../../src/pages/🇺🇸🇨🇦🇲🇽.js" /* webpackChunkName: "component---src-pages-🇺🇸-🇨🇦-🇲🇽-js" */)
}

